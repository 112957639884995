import React from 'react';
import { Grid } from '@mui/material';


function logos() {
  return (
    <div className='backofHome'>
      <Grid container spacing={1}>
        <Grid item md={3.5} lg={3.5}></Grid>
        <Grid item md={5} lg={5}>
          <a href='/HomeSackBoys' className='casino-link'>
            <div className='containerx Enter_the_Casino'>
              {/* The hover text will be displayed when hovered */}
              <div className="hover-text">ENTER THE CASINO</div>
            </div>
          </a>
        </Grid>
        <Grid item md={3.5} lg={3.5}></Grid>
      </Grid>


<div className='container'>

  
<Grid container spacing={1}>
        <Grid item md={4} lg={4}>
          <br/>
        <a href='https://www.azteccrypto.xyz/' className='casino-linkx'>
            <div className=' containerxx Enter_the_Casino'>
              {/* The hover text will be displayed when hovered */}
              <div className="hover-textx">AZTEC CRYPTO</div>
            </div>
          </a>

        </Grid>
        <Grid item md={8} lg={8}>
        
        </Grid>
       
      </Grid>
</div>

    </div>
  );
}

export default logos;
