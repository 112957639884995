import React from 'react'
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
function Nav() {
  return (
    <div> <br/><br/> <div className="bottomnav">
    <nav>
      <a href="./HomeSackBoys"  className='nav_bottom'>Home</a>
      <a href="/Utility" className='nav_bottom'>  Utility</a>
    
      <a href="/Story" className='nav_bottom'>Story</a>
      <a href="/SackBoys" className='nav_bottom'>Collection</a>
      <a href="/FAQ" className='nav_bottom'>Faq</a>
      <a href="/Team" className='nav_bottom'>Team</a>
      <a href="https://discord.gg/jnSqqPZQ" className='nav_bottom' style={{position:"relative" , top:"5px"}}>
        <FaDiscord />
      </a>
      <a href="https://x.com/SackBoysNFT" className='nav_bottom' style={{position:"relative" , top:"5px"}}>
        <FaXTwitter />
      </a>
    </nav>
  </div></div>
  )
}

export default Nav