import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { gsap } from 'gsap';
import Nav from './MobileLongNav';
import img1 from './sackboys/1.png';
import img2 from './sackboys/2.png';
import img3 from './sackboys/3.png';
import img4 from './sackboys/4.png';
import img5 from './sackboys/5.png';
import img6 from './sackboys/6.png';
import img7 from './sackboys/7.png';
import img8 from './sackboys/8.png';
import img9 from './sackboys/9.png';


function Story() {
  useEffect(() => {
    // GSAP Animation for 3D effect and animated borders
    gsap.utils.toArray('.story-img').forEach((img) => {
      gsap.set(img, { transformStyle: 'preserve-3d', border: '2px solid transparent' });
      gsap.to(img, {
       
        borderColor: 'rgba(255, 255, 255, 0.6)',
 
      });

      // Hover effect for 3D tilt and border animation
      img.addEventListener('mouseenter', () => {
        gsap.to(img, {
        
          ease: 'power2.out',
          borderColor: 'rgba(255, 255, 255, 1)',
        });
      });

      img.addEventListener('mouseleave', () => {
        gsap.to(img, {
         
          ease: 'power2.in',
          borderColor: 'rgba(255, 255, 255, 0.6)',
        });
      });
    });
  }, []);

  return (
    <div className='backs'>
      <div className='glass-effect'></div>
      <br /> <br />
      <div className='sack_story_head'>
      <h1 className='sack_story_head1'>SACK BOYS</h1> <br />
      </div>
     

      <div className='container'>
        <Grid container className='sack_story_head' spacing={2}>



          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img1} className='story-img' style={{ width: '100%' }} alt="Sackboy Chain" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img2} className='story-img' style={{ width: '100%' }} alt="Sackboy Strips" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img3} className='story-img' style={{ width: '100%' }} alt="Sackboy Cube Pink" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img4} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img5} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img6} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img7} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img8} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
          <Grid item md={6} lg={4} sm={12} xs={12}>
            <img src={img9} className='story-img' style={{ width: '100%' }} alt="Sackboy Graphics" />
          </Grid>
        </Grid>
      </div>
      <br /> <br />
      <Nav />
    </div>
  );
}

export default Story;
