import React, { useState } from 'react';
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaBars } from "react-icons/fa"; // Hamburger icon
import { FaTimes } from "react-icons/fa"; // Close icon

function Nav() {
  const [isOpen, setIsOpen] = useState(false); // State to manage nav visibility

  const toggleNav = () => {
    setIsOpen(!isOpen); // Toggle nav visibility
  };

  return (
    <div><br/>
      {/* Hamburger Icon */}
      <div className="hamburgerm" onClick={toggleNav}>
        {isOpen ? <FaTimes /> : <FaBars />}<br/>
      </div>
      <br/>
      {/* Bottom Navigation */}
      <div className={`bottomnavm ${isOpen ? 'open' : ''}`}>
        <nav>
          <a href="/HomeSackBoys" className="nav_bottomm">Home</a>

          <a href="/Utility" className="nav_bottomm">Utility</a>
      
          <a href="/Story" className="nav_bottomm">Story</a>
          <a href="/SackBoys" className="nav_bottomm">Collection</a>

          <a href="/FAQ" className="nav_bottomm">Faq</a>
          <a href="/Team" className="nav_bottomm">Team</a>
   
        
          <a href="https://x.com/SackBoysNFT" className="nav_bottomm">
            <FaXTwitter />
          </a>
          <a href="https://discord.gg/jnSqqPZQ" className="nav_bottomm">
            <FaDiscord />
          </a>
        </nav>
        <br/> <br/>
      </div>
    </div>
  );
}

export default Nav;
