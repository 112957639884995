import React from 'react';
import { Grid } from '@mui/material';
import Nav from './MobileLongNav';
import City from './city.jpg'
function Story() {
  return (
    <div className='backs'>
      <div className='glass-effect'></div>
    
     

      <br/> <br/> {/* Glass effect overlay */}
      <div className='container'>
    

<Grid container className='sack_story_head'>
<img src={City} style={{width:"100%" , borderRadius:"10px"}}/>
<br/> <br/> {/* Glass effect overlay */}
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <h1 className='sack_story_head1m'>The Stitched City and The Loom</h1> 
            <br/> 
            <h5 className='sack_story_head12m'>

            The SackBoys live in The Stitched City, a strange yet vibrant metropolis built from forgotten and discarded materials. At the heart of their world lies The Loom, an ancient and mystical entity that powers not just the city, but the very existence of the SackBoys. The Loom holds objects of immense power—artifacts that maintain the balance of the world. These objects, woven directly into the fabric of reality, are vital to keeping The Stitched City stable.

            </h5>


          </Grid>
        </Grid>
      </div>
      <br/>  {/* Glass effect overlay */}

      <div className='container'>
        <Grid container className='sack_story_head'>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <h1 className='sack_story_head1m'>The Gambling King’s Heist</h1> 
            <br/> 
            <p className='sack_story_head12m'>

            When the Gambling King—a powerful enforcer of the corporate entity known as The House—discovers the existence of The Loom and its magical objects, he sees an opportunity. The Gambling King, obsessed with greed and power, infiltrates The Stitched City, planning to steal these valuable artifacts for The House. 
            <br/>    <br/> 
Using his vast resources, he sends his minions into the depths of The Loom, snatching away the ancient objects, each one representing a different aspect of the SackBoys' world: The Needle of Fate, The Scissors of Time, The Thread of Life, and more. With these objects in his possession, the Gambling King can manipulate reality itself, bending time, controlling life, and unravelling the very fabric of The Stitched City.

            </p>


          </Grid>
        </Grid>
      </div>
     



      <br/>  {/* Glass effect overlay */}

<div className='container'>
  <Grid container className='sack_story_head'>
    <Grid item md={12} lg={12} sm={12} xs={12}>
      <h1 className='sack_story_head1m'>The SackBoys' Mission</h1> 
      <br/> 
      <p className='sack_story_head12m'>

      

      As the objects are stolen, The Stitched City begins to unravel. The skies darken, buildings collapse, and the SackBoys start to fade away. Realizing that their world is falling apart, the SackBoys form an alliance known as the Threadbare Rebellion. Their mission is clear: they must retrieve the stolen objects from the Gambling King before The Loom completely unravels, and their entire existence is erased.
      <br/>  <br/> 
The SackBoys must navigate dangerous territories, outsmart the Gambling King’s henchmen, and battle through traps and challenges to recover the stolen items. Each object is hidden in a different part of The Loom, guarded by twisted creatures and traps, designed to break their spirits. The Gambling King mocks them at every turn, offering impossible wagers and betting against their success.


      </p>


    </Grid>
  </Grid>
</div>









<br/>  {/* Glass effect overlay */}

<div className='container'>
  <Grid container className='sack_story_head'>
    <Grid item md={12} lg={12} sm={12} xs={12}>
      <h1 className='sack_story_head1m'>The Final Gamble</h1> 
      <br/> 
      <p className='sack_story_head12m'>

      

      The SackBoys know that to defeat the Gambling King, they must not only retrieve the stolen objects but also challenge him directly. The Gambling King, fueled by his insatiable greed, offers them a final deal: if they can win back all the objects through a series of games and challenges, he will leave The Stitched City and return the artifacts. But if they fail, The Loom will be destroyed, and The House will own everything.
      <br/> <br/>
The SackBoys must rely on their wits, bravery, and their knowledge of The Loom to win back the sacred objects and stop the Gambling King’s grip from destroying their world.



      </p>


    </Grid>
  </Grid>
</div>


<br/> <br/>
<Nav/>
    </div>
  );
}

export default Story;
