import React from 'react';
import Nav from './Nav';

import { Grid } from '@mui/material';

function Logos() {
  return (
    <div className="banner_main">   <br/>
  
      <Nav/>
    </div>
  );
}

export default Logos;
