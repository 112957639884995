import React from 'react'
import Marquee from "react-fast-marquee";
function Marquees() {
  return (
    <div className='backofitfeelglassym'>
        <Marquee>    &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#fff" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <h4 style={{fontSize:"24px" , color:"#000" , fontWeight:"500"}}> Dropping Soon</h4>
        &nbsp;&nbsp; &nbsp;&nbsp;

        </Marquee>


    </div>
  )
}

export default Marquees