import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { gsap } from 'gsap';
import Nav from './MobileLongNav';

function Story() {
  const [expanded, setExpanded] = useState(false);
  const accordionRefs = useRef([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMouseEnter = (panel) => () => {
    setExpanded(panel);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  useEffect(() => {
    accordionRefs.current.forEach((accordion, index) => {
      gsap.fromTo(
        accordion,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          delay: index * 0.2,
          ease: 'power2.out',
        }
      );
    });
  }, []);

  const renderAccordion = (panel, title, details, refIndex) => (
    <Accordion
      expanded={expanded === panel}
      onChange={handleChange(panel)}
      onMouseEnter={handleMouseEnter(panel)}
      onMouseLeave={handleMouseLeave}
      className="custom-accordion"
      ref={(el) => (accordionRefs.current[refIndex] = el)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panel}-content`}
        id={`${panel}-header`}
      >
        <span className="accordion-titlem">{title}</span>
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );

  return (
    <div className="backs">
      <div className="glass-effect"></div>
<br/>
      <div className="sack_story_head">
        <h1 className="sack_story_head1m">Frequently Asked Questions</h1>
        <br/>
        <div className="container">
          {renderAccordion(
            'panel1',
            'What Makes SackBoys Different from Other NFTs?',
            `SackBoys isn’t just another NFT collection—it’s a living, breathing story. Each SackBoy is a unique digital asset, but also a hero in a grand narrative. As a SackBoy holder, you're not just an owner—you’re a key player in the fight to save The Stitched City from unraveling. Your SackBoy participates in exclusive games, story events, and challenges tied to the ongoing lore.`,
            0
          )}

          {renderAccordion(
            'panel2',
            'What is The Stitched City?',
            `The Stitched City is a magical metropolis built from forgotten and discarded materials. It is powered by The Loom, an ancient entity that weaves the fabric of reality and keeps the city stable. However, with the theft of vital artifacts, The Stitched City is slowly falling apart.`,
            1
          )}

{renderAccordion(
            'panel3',
            'What is The Loom?',
            `The Loom is the heart of the SackBoys' world, a mystical force that controls the balance of reality within The Stitched City. It holds powerful artifacts, such as The Needle of Fate, The Scissors of Time, and The Thread of Life. These artifacts maintain the fabric of existence and are essential to the SackBoys’ survival.`,
           2
          )}

{renderAccordion(
            'panel4',
            'Who is the Gambling King?',
            `The Gambling King is the main antagonist in the SackBoys' universe. He is a ruthless enforcer of the corporate entity known as The House, who discovers the powerful artifacts of The Loom and plans to steal them for his own selfish purposes. By controlling these objects, he can manipulate time, life, and the very reality of The Stitched City.`,
           3
          )}

{renderAccordion(
            'panel5',
            'What is the SackBoys mission?',
            `The SackBoys must stop the Gambling King from destroying their world. After he steals the powerful artifacts from The Loom, the SackBoys form the Threadbare Rebellion. Their goal is to retrieve these magical objects, battle dangerous enemies, and stop the city from unraveling.`,
           4
          )}


{renderAccordion(
            'panel6',
            'How does the final gamble work?',
            `In the final gamble, the Gambling King offers the SackBoys a deadly deal: if they can win back the artifacts through a series of challenges and games, he will return the items and leave The Stitched City. If they fail, The Loom will be destroyed, and the SackBoys' world will be lost to The House forever.`,
           5
          )}

{renderAccordion(
            'panel7',
            'How does the final gamble work?',
            `In the final gamble, the Gambling King offers the SackBoys a deadly deal: if they can win back the artifacts through a series of challenges and games, he will return the items and leave The Stitched City. If they fail, The Loom will be destroyed, and the SackBoys' world will be lost to The House forever.`,
           6
          )}
        </div><br/><br/><br/>
      </div><br/><br/>

      <Nav />
    </div>
  );
}

export default Story;
