import React from 'react'
import casino from './casino/c8.jpg'
import MobileLongNav from './MobileLongNav1'
import Marquee from './Marquee'
import DropMarquee from './DropMarquee'
function SackBoysHomeMobile() {
  return (
    <div className='backs'>
      <div className='glass-effect'></div>
    
      <div className='sack_story_head'>
        <a href='./'><img src={casino} style={{width:"100%"}}/></a>
     <DropMarquee/> 

<div className='container'>
<MobileLongNav/>


</div> 

<br />
<Marquee/>
      </div>
    
    </div>
  )
}

export default SackBoysHomeMobile