import React, { useEffect } from 'react';

import { gsap } from 'gsap';
import Nav from './Nav';

import { Grid } from '@mui/material';

import Tyler from './team/1.png'
import Noor from './team/2.png'

function Story() {
  useEffect(() => {
    // GSAP Animation for 3D effect and animated borders
    gsap.utils.toArray('.story-img').forEach((img) => {
      gsap.set(img, { transformStyle: 'preserve-3d', border: '2px solid transparent' });
      gsap.to(img, {
       
        borderColor: 'rgba(255, 255, 255, 0.6)',
 
      });

      // Hover effect for 3D tilt and border animation
      img.addEventListener('mouseenter', () => {
        gsap.to(img, {
        
          ease: 'power2.out',
          borderColor: 'rgba(255, 255, 255, 1)',
        });
      });

      img.addEventListener('mouseleave', () => {
        gsap.to(img, {
         
          ease: 'power2.in',
          borderColor: 'rgba(255, 255, 255, 0.6)',
        });
      });
    });
  }, []);

  return (
    <div className='backs'>
      <div className='glass-effect'></div>
      <br /> <br />
      <div className='sack_story_head'>
      <h1 className='sack_story_head1'>SACK BOYS TEAM </h1>
       <br />

<div className='container'>
<Grid container spacing={5}>
<Grid item md={6} lg={6} xs={6} sm={6}>
<div className='backbox'> 
<div className='cpntainer'> 
 <img src={Tyler} style={{width:"100%" , border:"1px solid #fff"}}/>
 <br/><br/>
 <h1 className='tyler'>TYLER (ARTIST)</h1>
 
</div>
</div>

  
</Grid>

<Grid item md={6} lg={6} xs={6} sm={6}>
<div className='backbox'> 
<div className='cpntainer'> 
 <img src={Noor} style={{width:"100%" , border:"1px solid #fff"}}/>
 <br/><br/>
 <h1 className='tyler'>Noor (Manager)</h1>
 
</div>
</div>

  
</Grid>
</Grid>


</div>


      </div>
     
      <br /> <br /><br /> 
    
      <br /> <br />
      <Nav />
    </div>
  );
}

export default Story;
