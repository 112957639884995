import React from 'react';
import { Grid } from '@mui/material';
import Nav from './MobileLongNav';

function Story() {
  return (
    <div className='backs'>
      <div className='glass-effect'></div>
    
     

      <br/> <br/> {/* Glass effect overlay */}
      <div className='container'>
        <Grid container className='sack_story_head'>
          <Grid item md={12} lg={12} sm={12} xs={12}>
            <h1 className='sack_story_head1m utcolor'>SACK BOYS UTLITY</h1> 
            <br/> 
            <p className='sack_story_head1m' style={{fontSize:"20px",}}>

            By owning one of our exclusive NFTs, you become an essential part of the Aztec community, gaining access to unique perks across our ecosystem. Here's what you can expect:

            </p>
            <>
            <ol className='sack_story_head1u' style={{fontSize:"20px"}}>
  <li>
    <strong className='strongu'>5% Discount on Aztec House Leveling Services</strong><br/>
    As an NFT holder, you’re not just part of the community—you’re treated like family. Enjoy a 5% discount on all contracts with Aztec House Leveling, turning your digital investment into real-world savings!
  </li>

  <li>
    <strong className='strongu'>Boosted Game Experience at Aztec Casino</strong><br/>
    Love to game? With your NFT, every playtime becomes a win-win. Earn 50% more game credits and enjoy 50% bigger jackpots, giving you more chances to hit it big in our Aztec Casino!
  </li>


  <li>
    <strong className='strongu'>Your Avatar in the Aztec Game</strong><br/>
    Your NFT isn’t just a token—it’s your in-game character! When we launch our exciting new game, your NFT transforms into your avatar, giving you a unique identity in the virtual world.
  </li>
</ol>

           

</>

          </Grid>
        </Grid>
      </div>
      <br/>  {/* Glass effect overlay */}

    

<br/> <br/>
<Nav/>
    </div>
  );
}

export default Story;
