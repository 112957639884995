import React, { useState, useEffect } from "react";  
import musicFile from "./Sack.mp3";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import { IoVolumeMute, IoVolumeHigh } from "react-icons/io5";

import Desktop from "./FAQDesktop";
import DesktopMobile from "./FaqMobile";

import mistVideo from './home.jpg'; 
import gsap from 'gsap';

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  
  useEffect(() => {
    const audioObj = new Audio(musicFile);
    audioObj.loop = true;
    setAudio(audioObj);

    return () => {
      audioObj.pause();
      audioObj.src = "";
    };
  }, []);

  const handleYesClick = () => {
    if (audio) {
      audio.play();
    }
  
    gsap.to(".mistVideo", { opacity: 0, duration: 4 });
    gsap.to(".modal-content", { opacity: 0, duration: 1, onComplete: () => setShowModal(false) });
    document.querySelector(".mistVideo").style.pointerEvents = "none";
    setShowContent(true);
  };
  

  const handleMuteToggle = () => {
    if (audio) {
      audio.muted = !audio.muted;
      setIsMuted(audio.muted);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const background = document.querySelector('.HomepageAbouths');
  
      if (background) {
        if (scrolled > 900) {
          background.style.backgroundPosition = `center ${(scrolled - 900) * 0.16}px`;
        } else {
          background.style.backgroundPosition = 'center 0px';
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div className="home-container">
      {/* Background Video */}
    

      <div className="content">
        {!smallScreen ? (
          <div className="HomepageAbouth mainfont">
             
            <Desktop />
          </div>
        ) : (
          <div className="mob">
            <DesktopMobile />
          </div>
        )}
      </div>


    
    </div>
  );
};

export default Home;
