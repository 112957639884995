import React, { useState } from 'react';
import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaBars } from "react-icons/fa"; // Hamburger icon
import { FaTimes } from "react-icons/fa"; // Close icon

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div><br/>
      <nav style={{textAlign:"center"}}><br/>
          
         
          <a href="/Story" className="nav_bottom" style={{fontSize:"25px"}}>Story</a>
          <hr className='hr'></hr>
          <a href="/Utility" className="nav_bottom" style={{fontSize:"25px"}}>Utility</a>
          <hr className='hr'></hr>
          <a href="/SackBoys" className="nav_bottom" style={{fontSize:"25px"}}>Collection</a>
          <hr className='hr'></hr>
          <a href="/FAQ" className="nav_bottom" style={{fontSize:"25px"}}>Faq</a>
          
          <hr className='hr'></hr>
          <a href="/Team" className="nav_bottom" style={{fontSize:"25px"}}>Team</a>
          <hr className='hr'></hr>
          <a href="https://www.azteccrypto.xyz/" className="nav_bottom" style={{fontSize:"25px" , color:"#E02184"}} >AZTEC CRYPTO</a>
          <hr className='hr'></hr>
          <a href="https://discord.gg/jnSqqPZQ" className="nav_bottom" style={{ position: "relative", top: "5px" }}>
            <FaDiscord style={{fontSize:"25px"}} />
          </a>
          
          <hr className='hr'></hr>
          <a href="https://x.com/SackBoysNFT" className="nav_bottom" style={{ position: "relative", top: "5px" }}>
            <FaXTwitter style={{fontSize:"25px"}} />
          </a>
        </nav><br/>
    </div>
  );
}

export default Nav;
