import React from 'react'

function MobileNavHome() {
  return (
    <div className='home_mobilepage'>
      <a href='/HomeSackBoys'>
      <br/>
      <div className='entercasino'>


      </div>
      
      </a>
      <br/>
      <br/>
      <br/>
      <br/>
     
     

    </div>
  )
}

export default MobileNavHome