import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';



import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
// import { useEffect, useState } from 'react';





AOS.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div> 
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>

  </></div>
 
);

reportWebVitals();
